import {Component, OnInit} from '@angular/core';
import {LocalCheckService} from '../../services/local-check.service';
import {Router} from '@angular/router';
import {AnswerData} from '../../data/AnswerData';
import {ItemData} from '../../data/ItemData';
import {AnswerResult} from '../../data/AnswerResult';
import {MatDialog} from '@angular/material/dialog';
import {DialogDeleteComponent} from '../dialog-delete/dialog-delete.component';
import {NgxSpinnerService} from 'ngx-spinner';
import {RemoteCheckService} from '../../services/remote-check.service';
import {LocalStorageService} from '../../services/local-storage.service';

@Component({
  selector: 'app-answer-list',
  templateUrl: './answer-list.component.html',
  styleUrls: ['./answer-list.component.css']
})
export class AnswerListComponent implements OnInit {

  answerList: Array<AnswerResult>;
  constructor(private localService: LocalCheckService,
              private localStorageService: LocalStorageService,
              private router: Router,
              public dialog: MatDialog,
              private spinner: NgxSpinnerService,
              private remoteService: RemoteCheckService) {
  }

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      this.getAvailableResult();
      this.spinner.hide();
    }, 1000);
  }

  newAnswer(): void {
    this.router.navigate(['answer', 'new']);
  }

  goToResultDetails(data: AnswerResult): void {
    this.router.navigate(['result', 'details', data.code]);
  }

  openDialog(data: AnswerResult): void {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      width: '450px',
      data: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteAnswer(data);
      }
    });
  }

  // TODO update source de données
  deleteAnswer(data: AnswerResult): void {
    this.spinner.show();
    const index = this.answerList.indexOf(data);
    if (index !== -1) {
      // récupération de la réponse et ça question ainsi que leurs index dans la liste au niveau du front
      let relatedAnswer;
      this.localStorageService.getOnDocumentWithCode(ItemData.ANSWER.toString(), data.responseCode).then(value => {
        relatedAnswer = value;
        this.localStorageService.updateDocumentOnCollection(ItemData.RESULT.toString(), data.code, {deleted: true});
        this.localStorageService.updateDocumentOnCollection(ItemData.ANSWER.toString(), relatedAnswer.code, {deleted: true});
        this.saveAnswerAndResultOnBackend(relatedAnswer, data);
      });
    }
  }

  getAvailableResult(): void {
    this.spinner.show();
    this.localStorageService.getAllData(ItemData.RESULT.toString()).then(value => {
      const result = value as Array<AnswerResult>;
      this.answerList = result.filter(res => !res.deleted).sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt));
      this.spinner.hide();
    }).catch(reason => {
      console.log(reason);
      this.spinner.hide();
    });
    // const result = this.localService.getData(ItemData.RESULT.toString()) as Array<AnswerResult>;
    // return result.filter(value => !value.deleted).sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt));
  }

  /**
   * Synchronize answer and result with the backend
   * @param answer
   * @param currentResult
   * @private
   */
  private saveAnswerAndResultOnBackend(answer: AnswerData, currentResult: AnswerResult): void {
    console.log('Synchronize Data');
    this.synchronizeAnswerAfterDeleting(answer);
    this.synchronizeResultAfterDeleting(currentResult);
  }

  private synchronizeAnswerAfterDeleting(answer: AnswerData): void {
    this.remoteService.genericMethodForPostRequest('/create-response', answer).then((res: any) => {
      const value = res.body;
      if (res.status === 201) {
        answer.id = value.id;
        answer.customer_id = value.customer_id;
        answer.synchronised = value.synchronised;
      } else if (res.status === 409) {
        answer.synchronised = true;
      }
      this.localStorageService.deleteDocumentOnCollection(ItemData.ANSWER.toString(), answer.code);
      console.log(res);
    }).catch(reason => {
      console.log(reason);
      if (answer.synchronised) {
        this.localStorageService.deleteDocumentOnCollection(ItemData.ANSWER.toString(), answer.code);
      }
    });
  }

  private synchronizeResultAfterDeleting(currentResult: AnswerResult): void {
    this.remoteService.genericMethodForPostRequest('/create-results', currentResult).then((res: any) => {
      const value = res.body;
      if (res.status === 201) {
        currentResult.id = value.id;
        currentResult.synchronised = value.synchronised;
      } else if (res.status === 409) {
        currentResult.synchronised = true;
      }
      console.log(value);
      this.localStorageService.deleteDocumentOnCollection(ItemData.RESULT.toString(), currentResult.code).then(value1 => {
        this.getAvailableResult();
      });
    }).catch(reason => {
      console.log(reason);
      if (currentResult.synchronised) {
        this.localStorageService.deleteDocumentOnCollection(ItemData.RESULT.toString(), currentResult.code).then(value1 => {
          this.getAvailableResult();
        });
      } else {
        this.getAvailableResult();
      }
    });
  }
}
