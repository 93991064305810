import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit(): void {
  }

  getCreditCheck(): void {
    this.route.navigate(['answer', 'list']);
  }

  gotToAgentDashboard(): void {
    this.route.navigate(['agent', 'kpi']);
  }
}
