import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {AnswerComponent} from './components/answer/answer.component';
import {AnswerListComponent} from './components/answer-list/answer-list.component';
import {AnswerDetailComponent} from './components/answer-detail/answer-detail.component';
import {ResultDetailComponent} from './components/result-detail/result-detail.component';
import {AuthGuardService} from './services/auth-guard.service';
import {HomePageComponent} from './components/home-page/home-page.component';
import {KpiStatsComponent} from './components/agents/kpi-stats/kpi-stats.component';
import {ClientParComponent} from './components/agents/client-par/client-par.component';
import {KpiVenteComponent} from './components/agents/kpi-vente/kpi-vente.component';
import {KpiProduitComponent} from './components/agents/kpi-produit/kpi-produit.component';
import {KpiAgentComponent} from './components/agents/kpi-agent/kpi-agent.component';
import {DetailsComponent} from "./components/agents/kpi-vente/details/details.component";

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'updated', redirectTo: 'answer/list', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'home/page', canActivate: [AuthGuardService], component: HomePageComponent},
  {path: 'answer', canActivate: [AuthGuardService], children: [
      {path: 'new', component: AnswerComponent},
      {path: 'list', component: AnswerListComponent},
      {path: 'details/:ref', component: AnswerDetailComponent}
  ]},
  {path: 'result', canActivate: [AuthGuardService], children: [
      {path: 'details/:ref', component: ResultDetailComponent}
  ]},
  {path: 'agent', canActivate: [AuthGuardService], children: [
      {path: 'kpi', component: KpiStatsComponent},
      {path: 'par', component: ClientParComponent},
      {path: 'vente', component: KpiVenteComponent},
      {path: 'vente/:par', component: KpiVenteComponent},
      {path: 'vente/details/:id', component: DetailsComponent},
      {path: 'produit', component: KpiProduitComponent},
      {path: 'kpi-agent', component: KpiAgentComponent}
    ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
