<app-spinner [text]="'Result Loading...'"></app-spinner>
<div class="container p-0 m-0">
  <h6 class="pt-2 text-secondary bold text-right">Détails sur le client, N°: <strong>{{id}}</strong></h6>
  <ng-container *ngIf="item">
    <div class="card mb-4 mx-1">
      <div class="card-header">
        <div class="d-flex flex-row justify-content-between align-content-end">
          <div>
            <small style="font-weight: 600">Non du client : {{item.customerFirstName + ' ' + item.customerLastName}}</small>
          </div>
          <div>
            <small style="font-weight: 600"><span [ngClass]="( statusList.includes(item.contractStatus)) ? 'text-success' : 'text-danger'"> Statut: {{item.contractStatus}}  </span></small>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-between align-content-end">
          <div *ngIf="item.downPaymentDate"><small style="opacity: .8; font-weight: bolder">Date d'acompte {{item.downPaymentDate | lowercase}}</small></div>
          <div class="text-danger" *ngIf="item.contractSwitchOffDate"><small style="opacity: .8; font-weight: bolder">Date d'expiration {{item.contractSwitchOffDate | lowercase}}</small></div>
        </div>
      </div>
      <div class="card-body">
        <div class="mb-4">
          <h6 class="text-black-50 text-uppercase">Contrat</h6>
          <div class="row mt-2">
            <div class="col-sm-12">
              <span>Numéro Installation: <strong>{{item.contractNumber}}</strong></span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-6">
              <span>Montant Credit: <strong>{{item.contractCreditAmount | currency :'XAF'}}</strong></span>
            </div>
            <div class="col-sm-6">
              <span>Acompte: <strong>{{item.downpaymentAmount | currency :'XAF'}}</strong></span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-6">
              <span>Montant Payé: <strong class="text-success">{{item.contractAmountPaid | currency :'XAF'}}</strong></span>
            </div>
            <div class="col-sm-6">
              <span>Montant Restant: <strong class="text-danger">{{item.contractAmountLeft | currency :'XAF'}}</strong></span>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <h6 class="text-black-50 text-uppercase">Système</h6>
          <div class="row mt-2">
            <div class="col-sm-6">
              <span>Produit: <strong>{{item.contractSystemVariant}}</strong></span>
            </div>
            <div class="col-sm-6">
              <span>Système: <strong>{{item.contractSystem}}</strong></span>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <h6 class="text-black-50 text-uppercase">Infos Client</h6>
          <div class="row mt-2">
            <div class="col-sm-6">
              <span>Téléphone: <strong>{{item.customerPhoneNumber}}</strong></span>
            </div>
            <div class="col-sm-6">
              <span>Village: <strong>{{item.customerVillage}}</strong></span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-6">
              <span>Region: <strong>{{item.customerRegion}}</strong></span>
            </div>
            <div class="col-sm-6">
              <span>Zone: <strong>{{item.customerZone}}</strong></span>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-12">
              <span>Itinéraire: <br><i>{{item.customerRoute}}</i></span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </ng-container>
  <ng-container *ngIf="!item">
    <div class="container">
      <h5>Client Non trouvé !</h5>
    </div>
  </ng-container>

  <button mat-raised-button style="width: 100%" class="upowa-color-last" (click)="gotBackToList()">
    Retour en arrière
  </button>
</div>
