import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {RemoteCheckService} from '../../services/remote-check.service';
import {LocalStorageService} from '../../services/local-storage.service';
import {Modeles} from '../../data/Modeles';
import {Router} from '@angular/router';
import {ItemData} from '../../data/ItemData';
import {QuestionBase} from '../../data/QuestionBase';
import {NgxSpinnerService} from 'ngx-spinner';
import {DeviceDetectorService} from 'ngx-device-detector';
import {InitializerData} from '../../data/InitializerData';
import firebase from 'firebase';
import UserInfo = firebase.UserInfo;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  deviceInfo = null;
  isAuthenticated: boolean;
  constructor(private authService: AuthService, private deviceService: DeviceDetectorService,
              private remoteService: RemoteCheckService,
              private localStorageService: LocalStorageService,
              private router: Router, private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.spinner.show();
    // Vérifier si l'utilisateur est connecté
    this.authService.isAuthenticated().then(
      (value: any) => {
        this.isAuthenticated = true;
        this.isAuthenticated = !!value;
        this.spinner.hide();
      });
  }

  /**
   * Authentification et récupération des information sur les question et les modèles depuis le backend
   */
  initAccountAndGetData(): void {
    this.spinner.show();
    if (localStorage.getItem(ItemData.USER.toString())) {
      this.gettingModelFromTheServer();
      this.gettingQuestionFromTheServer();
      this.router.navigate(['home', 'page']).then(value => {
        location.reload();
      });
      this.spinner.hide();
    } else {
      this.login() // Authentication Firebase
        .then((value: any) => {
          this.gettingModelFromTheServer();
          this.gettingQuestionFromTheServer();
          this.router.navigate(['home', 'page']).then(value1 => {
            location.reload();
          });
          localStorage.setItem(ItemData.UPDATE.toString(), ItemData.VERSION.toString());
          this.spinner.hide();
        })
        .catch(reason => {
          console.log(reason);
          this.spinner.hide();
        });
    }
  }

  private gettingModelFromTheServer(): void {
    this.localStorageService.getOneDocumentWithKey(ItemData.MODELS.toString(), 'MD60').then(value => {
      console.log(value);
      if (value === null) {
        this.remoteService.genericMethodForGettingData('/models')
          .then((mods: any) => {
            const models = mods as Array<Modeles>;
            this.transformAndSaveModel(models);
          })
          .catch(reason => {
            console.log(reason);
          });
      }
    }).catch(reason => {
      console.log(reason);
    });
    /*if (!this.localStorageService.loadInfo(ItemData.MD60.toString())) {
      // Récupération des models
      this.remoteService.genericMethodForGettingData('/models')
        .then((mods: any) => {
          const models = mods as Array<Modeles>;
          this.transformAndSaveModel(models);
        })
        .catch(reason => {
          console.log(reason);
        });
    }*/
  }

  private transformAndSaveModel(models: Array<Modeles>): void {
    const model60 = models.filter(model => model.title === 'MD60')[0];
    const model90 = models.filter(model => model.title === 'MD90')[0];
    /*this.localStorageService.setInfo(ItemData.MD60.toString(), model60);
    this.localStorageService.setInfo(ItemData.MD90.toString(), model90);*/
    this.localStorageService.addDocumentOnCollectionWithKey(ItemData.MODELS.toString(), model60, ItemData.MD60.toString());
    this.localStorageService.addDocumentOnCollectionWithKey(ItemData.MODELS.toString(), model90, ItemData.MD90.toString());
  }

  private gettingQuestionFromTheServer(): void {
    this.localStorageService.getOnDocumentQuestionWithOrdre(ItemData.QUESTION.toString(), 1).then((value: QuestionBase<any>) => {
      console.log(value);
      if (value === null || value === undefined) {
        // Récupération des questions
        this.remoteService.genericMethodForGettingData('/questions')
          .then((quests: any) => {
            const questions = quests as Array<QuestionBase<any>>;
            this.transformAndSaveQuestion(questions);
          })
          .catch(reason => {
            console.log(reason);
            this.spinner.hide();
          });
      }
    }).catch(reason => {
      console.log(reason);
      this.spinner.hide();
    });
    this.localStorageService.getAllData(ItemData.QUESTION.toString()).then(value => {
      console.log(value);

    }).catch(reason => {
      console.log(reason);
    });
  }

  private transformAndSaveQuestion(questions: Array<QuestionBase<any>>): void {
    questions.map(
      q => q.controlType === 'radio' ?
        q.options = [{cle: '1', valeur: 'OUI'}, {cle: '0', valeur: 'NON'}] :
        q.controlType === 'dropdown' ?
          q.options = [{cle: '10000', valeur: 'Flash'}, {cle: '15000', valeur: 'Luciole'}] :
          q
    );
    questions.forEach(question => this.localStorageService.addDocumentOnCollection(ItemData.QUESTION.toString(), question));
    // this.localStorageService.setInfo(ItemData.QUESTION.toString(), questions);
  }

  async login(): Promise<any> {
    await this.authService.googleLogin().then(
      (value: any) => {
        const user = value.user as UserInfo;
        if (!user.email.includes('upowa')) {
          alert('Connectez vous avec un compte upowa pour avoir accès à l\'application');
          return false;
        } else {
          const initData: InitializerData = {
            userEmail: user.email,
            userName: user.displayName
          };
          this.epicFunction(initData);
          this.isAuthenticated = true;
          localStorage.setItem(ItemData.USER.toString(), user.email);
        }
      },
      reason => {
        this.isAuthenticated = false;
      }
    );
  }

  async logout(): Promise<void> {
    await this.authService.signOut();
    this.isAuthenticated = false;
    this.localStorageService.clearAllLocalStorage();
  }

  /**
   * <p>
   * Function qui permet d'optenir les information sur la matériel sur lequel l'app est entrain de tourner
   * Ce travail à été fait grace à la librairie ngx-device-dectector
   * </p>
   * <br>
   * link : https://www.npmjs.com/package/ngx-device-detector
   */
  epicFunction(initData: InitializerData): void {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.deviceInfo.isMobile = this.deviceService.isMobile();
    this.deviceInfo.isTablet = this.deviceService.isTablet();
    this.deviceInfo.isDesktopDevice = this.deviceService.isDesktop();
    initData.phoneModel = JSON.stringify(this.deviceInfo);
    this.remoteService.genericMethodForPostRequestV1('/create-initializer', initData);
  }
}
