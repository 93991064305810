<div class="card mb-1 mx-1" [ngClass]="result.customerRejected ? 'border-danger' : 'border-success'">
  <div class="card-header">
    <div fxFlex="column" fxFlexAlign="center center" fxLayoutGap="5px" (click)="onDetails(result)" style="margin-left: 5px">
      <div>
        <small style="opacity: .8">Client Rejetté : </small>
        <span class="bold" [ngClass]="result.customerRejected ? 'red-color' : 'green-color'" >{{result.customerRejected ? 'OUI' : 'NON'}}</span>
      </div>
      <div><small style="opacity: .7">{{result.createdAt | date:'dd-MM-YYYY HH:mm'}}</small></div>
    </div>
    <button mat-icon-button>
      <mat-icon *ngIf="result.synchronised" class="green-color"> sync</mat-icon>
      <mat-icon *ngIf="!result.synchronised" class="red-color"> sync_disabled</mat-icon>
    </button>
    <a mat-icon-button class="close red-color" (click)="onDelete(result)" style="padding: 0; margin-right: 25px">
      <mat-icon>delete</mat-icon>
    </a>
  </div>
</div>
