import {Component, OnInit} from '@angular/core';
import {AgentClientData} from '../../../../data/AgentClientData';
import {NgxSpinnerService} from 'ngx-spinner';
import {LocalStorageService} from '../../../../services/local-storage.service';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {ItemData} from '../../../../data/ItemData';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {
  id: number;
  item: AgentClientData;
  statusList: string[] = ['Activé', 'Remboursé', 'En attente acompte'];
  constructor(private spinner: NgxSpinnerService, private route: ActivatedRoute,
              private router: Router,  private localService: LocalStorageService,
              private location: Location) { }

  ngOnInit(): void {
    this.spinner.show();
    this.id = this.route.snapshot.params.id;
    if (isNotNullOrUndefined(this.id)) {
      this.getCustomerInLocal(this.id);
    }
  }

  private getCustomerInLocal(id: number): void {
    this.localService.getAllData(ItemData.AGENT_SALES).then((value: Array<AgentClientData>) => {
      this.item = value.filter(value1 => value1.contractNumber == id)[0];
      console.log(this.item);
      this.spinner.hide();
    }).catch(reason => {
      console.log(reason);
      this.spinner.hide();
    });
  }

  gotBackToList(): void {
    this.location.back();
  }
}
