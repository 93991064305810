<app-spinner [text]="'Result Loading...'"></app-spinner>
<div class="container make-overflow-answer p-10" fxLayout="column" fxLayoutGap="10px" fxFlexAlign="space-between center">
  <h2>Resultat après calcul</h2>
  <mat-card class="example-card" *ngIf="result">
    <mat-card-header>
      <mat-card-title>
        <small style="opacity: .9">Client Rejetté : </small>
        <span class="bold" [ngClass]="result.customerRejected ? 'red-color' : 'green-color'" >{{result.customerRejected ? 'OUI' : 'NON'}}</span><br/><br/>
        <small>A écrire sur le contrat : </small><span class="bold" *ngIf="!result.customerRejected" [ngClass]="result.customerRejected ? 'red-color' : 'green-color'" >{{result.creditCheckCode}}</span>
      </mat-card-title>
      <mat-card-subtitle>
        <br/>
        <small style="opacity: .9">Fait le : {{result.createdAt | date:'dd-MM-YYYY HH:mm'}}</small>
      </mat-card-subtitle>
    </mat-card-header>
    <img src="assets/images/logo.png" class="responsive-item" alt="Photo of a Shiba Inu">
    <mat-card-content *ngIf="false">
      <div>
        <h4> Resultats détailés </h4>
        <h5>Resultat par Modèles</h5>
        <div>
          <span>Mauvais client modèle 60 : <span class="bold" [ngClass]="result.badCustomer60 ? 'red-color' : 'green-color'" >{{result.badCustomer60 ? 'OUI' : 'NON'}}</span></span><br>
          <span>Mauvais client modèle 90 : <span class="bold" [ngClass]="result.badCustomer90 ? 'red-color' : 'green-color'" >{{result.badCustomer90 ? 'OUI' : 'NON'}}</span></span>
        </div>
        <h5>Score par modèles</h5>
        <div>
          <span>Score modèle 60 : <span class="bold">{{result.score60 | number: '1.0-10'}}</span></span><br>
          <span>Score modèle 90 : <span class="bold">{{result.score90 | number: '1.0-10'}}</span></span>
        </div>
        <h5>Cuit point par modèle</h5>
        <div>
          <span>Cuit point modèle 60 : <span class="bold">{{result.cutPoint60 | number}}</span></span><br>
          <span>Cuit point modèle 90 : <span class="bold">{{result.cutPoint90 | number}}</span></span>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div class="button" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-evenly none" >

        <button mat-raised-button fxFlex="50"
                (click)="backToResultList()"
                matTooltip="Enregistrer une réponse"
                matTooltipClass="example-tooltip-red"
                aria-label="annuler"
                class="upowa-color-last responsive-item">
          Resultats
        </button>
        <button mat-raised-button fxFlex="50"
                (click)="addOneMore()"
                matTooltip="Enregistrer une réponse"
                matTooltipClass="example-tooltip-red"
                aria-label="enregistrer une réponse"
                class="upowa-color-primary responsive-item">
          Ajouter
        </button>
      </div>
    </mat-card-actions>
  </mat-card>

</div>
