<app-spinner [text]="'Chargement...'"></app-spinner>
<div class="container-fluid">
  <section #shoes class="make-overflow-answer mt-2" *ngIf="answerList && answerList.length > 0">
    <div *ngFor="let result of answerList" class="responsive-item" style="padding-bottom: 10px !important;">
      <app-answer-item [result]="result" (deleteClick)="openDialog($event)" (detailsClick)="goToResultDetails($event)"></app-answer-item>
    </div>
  </section>
  <div style="padding: 20px" *ngIf="!(answerList && answerList.length > 0)">
    <p>.</p>
  </div>
  <section class="add-button">
    <div class="example-button-row">
      <div class="example-flex-container">
        <div class="example-button-container">
          <button mat-fab aria-label="add answer icon" class="upowa-color-primary" (click)="newAnswer()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </section>
</div>
