<app-spinner [text]="'Chargement...'"></app-spinner>
<div class="" > <!-- d-flex flex-column justify-content-between align-content-center flex-wrap -->
  <div class="d-flex flex-column justify-content-between mb-2">
    <div style="height: 15vh"></div>
    <div style="width: 80%; margin-left: auto; margin-right: auto" class="d-flex flex-column justify-content-between align-items-center">
      <div class="d-flex flex-column justify-content-between align-items-center">
        <img src="https://upix.upowa.org/index.php/s/fNW8d9bpkWzpRAE/preview" class="responsive-item" alt="Credit Check" style="cursor: pointer; margin: 10px; border-radius: 15px" (click)="getCreditCheck()">
        <button mat-raised-button style="width: 100%" class="upowa-color-last" (click)="getCreditCheck()">
          Credit-Check (C.C.)
        </button>
      </div>
      <div class="d-flex flex-column justify-content-between align-items-center">
        <img src="https://upix.upowa.org/index.php/s/TDd4E5SbPjgZgpx/preview" class="responsive-item" alt="agent dashboard"  style="cursor: pointer; margin: 10px; border-radius: 15px" (click)="gotToAgentDashboard()">
        <button mat-raised-button style="width: 100%" class="upowa-color-last" (click)="gotToAgentDashboard()">
          Mon Dashboard
        </button>
      </div>
    </div>
    <div style="height: 20vh"></div>
  </div>
</div>
