export class AnswerResult {
  id?: string;
  code?: string;
  responseCode?: string;
  score60?: number;
  score90?: number;
  cutPoint60?: number;
  cutPoint90?: number;
  badCustomer60?: boolean;
  badCustomer90?: boolean;
  customerRejected?: boolean;
  synchronised?: boolean;
  creditCheckCode?: string;
  deleted?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}
