import {Injectable} from '@angular/core';
import {AnswerData} from '../data/AnswerData';
import {AnswerResult} from '../data/AnswerResult';
import {v4 as uuidv4} from 'uuid';
import {LocalStorageService} from './local-storage.service';
import {Modeles} from '../data/Modeles';
import {ItemData} from '../data/ItemData';
import uniqueRandom from 'unique-random';

const random = uniqueRandom(1, 99999);
@Injectable({
  providedIn: 'root'
})
export class FormulaCalculatorService {

  constructor(private localStorageService: LocalStorageService) {
  }

  public geResultModel60And90(answer: AnswerData): AnswerResult {
    const result: AnswerResult = new AnswerResult();
    /*let model60 = this.localStorageService.loadInfo('MD60') as Modeles;
    let model90 = this.localStorageService.loadInfo('MD90') as Modeles; */
    let model60;
    let model90;

    this.localStorageService.getOneDocumentWithKey(ItemData.MODELS.toString(), ItemData.MD60.toString()).then(md60 => {
      model60 = md60;
      this.localStorageService.getOneDocumentWithKey(ItemData.MODELS.toString(), ItemData.MD90.toString()).then(md90 => {
        model90 = md90;

        result.code = uuidv4();
        result.createdAt = new Date();
        result.updatedAt = new Date();
        result.cutPoint60 = 0.35;
        result.cutPoint90 = 0.25;
        result.responseCode = answer.code;
        result.deleted = false;
        result.synchronised = false;

        // Calcul des scores pour chaque Modèle
        result.score60 = this.calculateScoreMD60(answer, model60);
        result.score90 = this.calculateScoreMD90(answer, model90);

        // Définir les variable Bad Client;
        result.badCustomer60 = result.score60 > result.cutPoint60;
        result.badCustomer90 = result.score90 > result.cutPoint90;

        result.customerRejected = result.badCustomer90 && result.badCustomer60 ? true : result.badCustomer90;
        if (!result.customerRejected) {
          result.creditCheckCode = 'UP' + random();
        }
      });
    });
    console.log(answer);
    console.log(model60);
    console.log(model90);
    return result;
  }

  private calculateScoreMD60(answer: AnswerData, model: Modeles): number {
    const result =  1 / (1 +
      Math.exp( -(model.interception +
                    this.calculateSumMD60Level1(answer, model) +
                    this.calculateSumMD60Level2(answer, model) +
                    this.calculateSumMD60Level3(answer, model)
                  )
      )
    );
    console.log('Result Score MD60');
    console.log(result);
    // return Math.round(result * 100) / 100;
    return result;
  }

  private calculateScoreMD90(answer: AnswerData, model: Modeles): number {
    const age = answer.age * model.age;
    const autres = answer.autres * model.autres;
    const extra = answer.extra_downpayment * model.extra_downpayment;
    const ewondo = answer.ewondo * model.ewondo;
    const veuf = answer.veuf * model.veuf;
    const orange = answer.orange * model.orange;
    const commercant = answer.commercant * model.commercant;
    const eleveur = answer.eleveur * model.eleveur;
    const independant = answer.independant * model.independant;
    const caculationToSum: number [] = [];
    caculationToSum.push(model.interception);
    caculationToSum.push(age);
    caculationToSum.push(this.calculateSumMD90Level1(answer, model));
    caculationToSum.push(autres);
    caculationToSum.push(extra);
    caculationToSum.push(ewondo);
    caculationToSum.push(this.calculateSumMD90Level2(answer, model));
    caculationToSum.push(veuf);
    caculationToSum.push(orange);
    caculationToSum.push(commercant);
    caculationToSum.push(eleveur);
    caculationToSum.push(independant);
    caculationToSum.push(this.calculateSumMD90Level3(answer, model));
    let sum = 0;
    caculationToSum.forEach(value => sum += value);
    const result = 1 / (1 + Math.exp(-(sum)));
    console.log('Result Score MD90');
    console.log(result);
    // return Math.round(result * 100) / 100;
    return result;
  }

  private calculateSumMD60Level1(answer: AnswerData, model: Modeles): number {
    const sumMD60L1: number[] = [];
    sumMD60L1.push(answer.age * model.age);
    sumMD60L1.push(answer.poisson * model.poisson);
    sumMD60L1.push(answer.cacao * model.cacao);
    sumMD60L1.push(answer.haricots * model.haricots);
    sumMD60L1.push(answer.manioc * model.manioc);
    sumMD60L1.push(answer.autres * model.autres);
    let sum = 0;
    sumMD60L1.forEach(value => sum += value);
    console.log('Sum Level 1 MD60');
    console.log(sum);
    return sum;
    // return Math.round(sum * 100) / 100;
  }

  private calculateSumMD60Level2(answer: AnswerData, model: Modeles): number {
    const sumMD60L2: number[] = [];
    sumMD60L2.push(answer.extra_downpayment * model.extra_downpayment);
    sumMD60L2.push(answer.ewondo * model.ewondo);
    let sum = 0;
    sumMD60L2.forEach(value => sum += value);
    console.log('Sum Level 2 MD60');
    console.log(sum);
    // return Math.round(sum * 100) / 100;
    return sum;
  }

  private calculateSumMD60Level3(answer: AnswerData, model: Modeles): number {
    const sumMD60L3: number[] = [];
    sumMD60L3.push(answer.orange * model.orange);
    sumMD60L3.push(answer.artisan * model.artisan);
    sumMD60L3.push(answer.commercant * model.commercant);
    sumMD60L3.push(answer.eleveur * model.eleveur);
    sumMD60L3.push(answer.fonctionnaire * model.fonctionnaire);
    sumMD60L3.push(answer.independant * model.independant);
    sumMD60L3.push(answer.retraite * model.retraite);
    sumMD60L3.push(answer.centre2 * model.centre2);
    sumMD60L3.push(answer.adamaoua * model.adamaoua);
    sumMD60L3.push(answer.revenues * model.revenues);
    let sum = 0;
    sumMD60L3.forEach(value => sum += value);
    console.log('Sum Level 3 MD60');
    console.log(sum);
    // return Math.round(sum * 100) / 100;
    return sum;
  }

  private calculateSumMD90Level1(answer: AnswerData, model: Modeles): number {
    const sumMD90L1: number[] = [];
    sumMD90L1.push(answer.cacao * model.cacao);
    sumMD90L1.push(answer.haricots * model.haricots);
    let sum = 0;
    sumMD90L1.forEach(value => sum += value);
    console.log('Sum Level 1 MD90');
    console.log(sum);
    // return Math.round(sum * 100) / 100;
    return sum;
  }

  private calculateSumMD90Level2(answer: AnswerData, model: Modeles): number {
    const sumMD90L2: number[] = [];
    sumMD90L2.push(answer.anglais * model.anglais);
    sumMD90L2.push(answer.bamileke * model.bamileke);
    let sum = 0;
    sumMD90L2.forEach(value => sum += value);
    console.log('Sum Level 2 MD90');
    console.log(sum);
    // return Math.round(sum * 100) / 100;
    return sum;
  }

  private calculateSumMD90Level3(answer: AnswerData, model: Modeles): number {
    const sumMD90L3: number[] = [];
    sumMD90L3.push(answer.centre2 * model.centre2);
    sumMD90L3.push(answer.adamaoua * model.adamaoua);
    sumMD90L3.push(answer.revenues * model.revenues);
    let sum = 0;
    sumMD90L3.forEach(value => sum += value);
    console.log('Sum Level 3 MD90');
    console.log(sum);
    // return Math.round(sum * 100) / 100;
    return sum;
  }
}
