<div>
<ng-container *ngFor="let item of globalSales | paginate:
        { itemsPerPage: 5, currentPage: p,
            totalItems: globalSales.length };">
  <div class="card mb-2 mx-1" [ngClass]="item.lateTimes > 0 ? 'border-danger' : 'border-success'">
    <div class="card-header">
      <div fxFlex="column" fxFlexAlign="center center" fxLayoutGap="5px" style="margin-left: 5px">
        <div>
          <small style="opacity: .9">Non du client : {{item.customerFirstName + ' ' + item.customerLastName}}</small>
        </div>
        <div>
          <small style="opacity: .9">Installation : {{item.contractNumber}} | <span [ngClass]="( statusList.includes(item.contractStatus)) ? 'text-success' : 'text-danger'"> Status: {{item.contractStatus}}  </span></small>
        </div>
        <div *ngIf="item.downPaymentDate"><small style="opacity: .8">Date d'accompte {{item.downPaymentDate | lowercase}}</small></div>
        <div *ngIf="item.contractSwitchOffDate"><small style="opacity: .8">Date d'expiration {{item.contractSwitchOffDate | lowercase}}</small></div>
      </div>
      <a mat-icon-button  class="close" (click)="onDetails(item)" style="padding: 0">
        <mat-icon>visibility</mat-icon>
      </a>
    </div>
    <div class="card-body">

    </div>
  </div>
</ng-container>
<pagination-controls
  *ngIf="globalSales.length > 0"
  (pageChange)="p = $event">
</pagination-controls>
</div>
