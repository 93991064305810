<app-spinner [text]="'Chargement...'"></app-spinner>
<div fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="60px" class="padding mt-5">
      <div>
        <img src="assets/images/logo.png" class="responsive-item" alt="Photo of a Shiba Inu">
      </div>
      <div>
        <button mat-raised-button
                matTooltip="Click to initialize the APP"
                matTooltipClass="example-tooltip-red"
                aria-label="connection"
                class="example-button, upowa-color-last responsive-item"
                (click)="initAccountAndGetData()">
          S'identifier sur Master Check
        </button>
      </div>
      <div>
        <p>Let's pOwer people now!</p>
        <mat-divider class="upowa-color-primary responsive-item"></mat-divider>
      </div>
</div>

<!--<div fxLayout="column center">
  <div fxFlexAlign="start">1. One</div>
  <div fxFlexAlign="center">2. Two</div>
  <div fxFlexAlign="center">3. Three</div>
  <div fxFlexAlign="end">4. Four</div>
</div>-->
