import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AnswerData} from '../data/AnswerData';
import {AnswerResult} from '../data/AnswerResult';

const baseUrl = environment.backendUrl;
const agentKpiUrl = environment.kpiAgentUrl;

@Injectable()
export class RemoteCheckService {

  constructor(private http: HttpClient) { }

  private static handleError(error: any): Promise<never> {
    const errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return Promise.reject(errMsg);
  }

  private static extractData(res: HttpResponse<any>): any {
    let body;
    if (res) {
      body = res.body;
    }
    return body;
  }

  private static extractDataV2(res: HttpResponse<any>): any {
    let body;
    if (res) {
      body = res;
    }
    return body;
  }

  public genericMethodForGettingData(path: string): Promise<any> {
    return this.http.get(baseUrl + path, {observe: 'response', responseType: 'json'}).toPromise()
      .then(RemoteCheckService.extractData)
      .catch(RemoteCheckService.handleError);
  }

  public genericMethodForGettingDataForKPI(path: string): Promise<any> {
    return this.http.get(agentKpiUrl + path, {observe: 'response', responseType: 'json'}).toPromise()
      .then(RemoteCheckService.extractData)
      .catch(RemoteCheckService.handleError);
  }

  public genericMethodForPostRequest(path: string, data: any): Promise<any> {
    return this.http.post(baseUrl + path, data,
      {observe: 'response', responseType: 'json'}).toPromise()
      .then(RemoteCheckService.extractDataV2)
      .catch(RemoteCheckService.handleError);
  }
  public genericMethodForPostRequestV1(path: string, data: any): Promise<any> {
    return this.http.post(baseUrl + path, data,
      {observe: 'response', responseType: 'json'}).toPromise()
      .then(RemoteCheckService.extractData)
      .catch(RemoteCheckService.handleError);
  }

  /**
   * Send answer data to the server
   * @param answer
   * @private
   */
  public synchroniseAnswerWithServer(answer: AnswerData): void {
    this.genericMethodForPostRequest('/create-response', answer).then((value: any) => {
      answer.id = value.id;
      answer.customer_id = value.customer_id;
      answer.synchronised = value.synchronised;
    }).catch(reason => {
      console.log(reason);
    });
  }

  /**
   * Send Result data to the server
   * @param currentResult
   * @private
   */
  public synchroniseResultWithServer(currentResult: AnswerResult): void {
    this.genericMethodForPostRequest('/create-results', currentResult).then((value: any) => {
      currentResult.id = value.id;
      currentResult.synchronised = value.synchronised;
    }).catch(reason => {
      console.log(reason);
    });
  }

}
