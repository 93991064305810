import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AnswerResult} from '../../../data/AnswerResult';

@Component({
  selector: 'app-answer-item',
  templateUrl: './answer-item.component.html',
  styleUrls: ['./answer-item.component.css']
})
export class AnswerItemComponent implements OnInit {
  @Input()
  result: AnswerResult;

  @Output()
  deleteClick: EventEmitter<AnswerResult> = new EventEmitter<AnswerResult>();

  @Output()
  detailsClick: EventEmitter<AnswerResult> = new EventEmitter<AnswerResult>();

  constructor() { }

  ngOnInit(): void {
  }

  onDelete(item: AnswerResult): void {
    this.deleteClick.emit(item);
  }

  onDetails(item: AnswerResult): void {
    this.detailsClick.emit(item);
  }

}
