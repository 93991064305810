import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-kpi-agent',
  templateUrl: './kpi-agent.component.html',
  styleUrls: ['./kpi-agent.component.css']
})
export class KpiAgentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
