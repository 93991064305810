<div [formGroup]="form" [ngSwitch]="question.controlType" *ngIf="question">

    <mat-form-field appearance="outline"  class="form-element">

      <mat-label [attr.for]="question.id">{{question.title}}</mat-label>

      <input *ngSwitchCase="'textbox'" [id]="question.id" matInput [placeholder]="question.title" [type]="question.dataType" [formControlName]="question.variable">

      <input matInput [matDatepicker]="picker" [formControlName]="question.variable" [id]="question.id" *ngSwitchCase="'date'">
      <mat-datepicker-toggle matSuffix [for]="picker" *ngSwitchCase="'date'"></mat-datepicker-toggle >
      <mat-datepicker #picker></mat-datepicker>

      <mat-select *ngSwitchCase="'dropdown'" [id]="question.id" [formControlName]="question.variable" (selectionChange)="sendPrixAcompte($event)">
        <mat-option *ngFor="let opt of question.options" [value]="opt.cle">{{opt.valeur}}</mat-option>
      </mat-select>

      <mat-select *ngSwitchCase="'radio'" [id]="question.id" [formControlName]="question.variable">
        <mat-option *ngFor="let opt of question.options" [value]="opt.cle">{{opt.valeur}}</mat-option>
      </mat-select>

      <mat-error class="errorMessage" *ngIf="!isValid">{{question.title}} is required</mat-error>

    </mat-form-field>

</div>
