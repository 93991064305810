import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-client-par',
  templateUrl: './client-par.component.html',
  styleUrls: ['./client-par.component.css']
})
export class ClientParComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
