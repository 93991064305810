import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-answer-detail',
  templateUrl: './answer-detail.component.html',
  styleUrls: ['./answer-detail.component.css']
})
export class AnswerDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
