<app-spinner [text]="'Calcul encoure...'"></app-spinner>
<div class="container p-10" fxLayout="column" fxLayoutAlign="space-between none" >
  <form (ngSubmit)="onSubmit()" [formGroup]="form" class="form make-overflow-answer">

    <div *ngFor="let question of questions">
      <app-question [question]="question" [form]="form" (acompteSelect)="updateAcomptePrix($event)"></app-question>
    </div>

    <div class="button" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-evenly none" >

      <button mat-raised-button fxFlex="50" type="reset"
              (click)="cancel()"
              matTooltip="Enregistrer une réponse"
              matTooltipClass="example-tooltip-red"
              aria-label="annuler"
              color="warn"
              class="responsive-item">
        Annuler
      </button>
      <button mat-raised-button fxFlex="50" type="submit"
                       matTooltip="Enregistrer une réponse"
                       matTooltipClass="example-tooltip-red"
                       aria-label="enregistrer une réponse"
                       class="upowa-color-last responsive-item"
                       [disabled]="!form.valid">
      Enregistrer
    </button>
    </div>
  </form>

  <div *ngIf="payLoad" class="form-row">
    <strong>Saved the following values</strong><br>{{payLoad}}
  </div>
</div>
