import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {QuestionBase} from '../../../data/QuestionBase';
import {MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'app-question',
  templateUrl: './dynamic-form-question.component.html',
  styleUrls: ['./dynamic-form-question.component.css']
})
export class DynamicFormQuestionComponent{

  @Input() question: QuestionBase<string>;
  @Input() form: FormGroup;
  @Output() acompteSelect: EventEmitter<any> = new EventEmitter<any>();

  get isValid(): boolean { return this.form.controls[this.question.variable].valid; }

  sendPrixAcompte(acompte: MatSelectChange): void {
    console.log('Book changed...');
    const selectAcompte = acompte.value;
    console.log(selectAcompte);
    this.acompteSelect.emit({
      acompte: selectAcompte
    });
  }
}
