<app-spinner [text]="'Result Loading...'"></app-spinner>
<div class="container p-0 m-0">
  <small class="pt-2">
    Données à jour (Connecté)
    <button style="margin-left: 5px" matTooltip="Mettre à mes Ventes" mat-icon-button  (click)="madeSync()">
      <mat-icon>sync</mat-icon>
    </button>
  </small>
  <hr>
  <h6 class="text-secondary bold text-right">Mes Clients <strong *ngIf="par">dans le PAR {{par}}</strong></h6>
  <div class="container">
    <h6>Effectuer un filtre</h6>
    <div class="form">
      <div class="row">
        <mat-form-field appearance="outline"  class="form-element col-sm-6">
          <mat-label>Nom</mat-label>
          <input matInput type="text" [(ngModel)]="name" (ngModelChange)="onFilterByName($event)">
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element col-sm-6">
          <mat-label>Install Number</mat-label>
          <input matInput type="number" [(ngModel)]="installNumber" (ngModelChange)="onFilterByInstallNumber($event)">
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="outline"  class="form-element col-sm-6">
          <mat-label>Niveau de PAR</mat-label>
          <mat-select [(ngModel)]="par" (selectionChange)="onFilterByPAR($event)">
            <mat-option *ngFor="let item of parList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline"  class="form-element col-sm-6" >
          <mat-label>Status</mat-label>
          <mat-select [(ngModel)]="status" (selectionChange)="onFilterByStatus($event)">
            <mat-option *ngFor="let item of statusList" [value]="item">{{item}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <hr style="width: 80%">
  <app-vente-item *ngIf="globalSales.length > 0" [globalSales]="globalSales"></app-vente-item>
  <div *ngIf="globalSales.length === 0">
    Aucune information trouvées
  </div>
</div>
