<app-spinner [text]="'Result Loading...'"></app-spinner>
<div class="container p-0 m-0">
  <small class="pt-2">
    Données à jour (Connecté)
    <button style="margin-left: 5px" matTooltip="Mettre à mes KPI" mat-icon-button  (click)="madeSync()">
      <mat-icon>sync</mat-icon>
    </button>
  </small>
  <hr>
  <h6 class="text-secondary bold text-right">Mon Dashboard</h6>
  <ng-container *ngIf="globalKpis.length > 0">
    <mat-card class="responsive-item" *ngFor="let kpi of globalKpis">
      <mat-card-header style="display: flex; flex-direction: column">
        <mat-card-title>
          <div class="row col-md-12">
            <div class="form-group col-md-6">
            <span>
              <small style="opacity: .9">Nom Agent : <span class="bold" >{{kpi.agentName}}</span></small>
            </span>
            </div>
            <div class="form-group col-md-6">
            <span>
              <small style="opacity: .9">Nombre de Client : <span class="bold" >{{kpi.clients}}</span></small>
            </span>
            </div>
          </div>
          <div class="row col-md-12">
            <div class="form-group col-md-6">
           <span>
              <small style="opacity: .9">Region : <span class="bold" >{{kpi.region}}</span></small>
            </span>
            </div>
            <div class="form-group col-md-6">
             <span>
              <small style="opacity: .9">Zone: <span class="bold" >{{kpi.zone}}</span></small>
            </span>
            </div>
          </div>
        </mat-card-title>
        <mat-card-subtitle style="font-weight: bold; font-size: medium">
          <div class="row col-md-12">
            <div class="form-group col-md-4">
              <span>
                <small><strong>Nbr Activés : <span class="bold" >{{kpi.activesUnits}}</span> | <span class="bold" >{{kpi.activesUnitsRate | percent}}</span></strong></small>
              </span>
            </div>
            <div class="form-group col-md-4">
              <span>
                <small><strong>Nbr Désactivés : <span class="bold" >{{kpi.desactivatedUnits}}</span> | <span class="bold" >{{kpi.desactivatedUnitsRate | percent}}</span></strong></small>
              </span>
            </div>
            <div class="form-group col-md-4">
              <span>
                <small><strong>Irrecouvrables : <span class="bold" >{{kpi.writtenOffUnits}}</span></strong></small>
              </span>
            </div>
            <div class="form-group col-md-4">
              <span>
                <small><strong>Déinstallés / Blacklistés : <span class="bold" >{{kpi.repossessionUnits}}</span></strong></small>
              </span>
            </div>
            <div class="form-group col-md-4">
              <span class="text-success">
                <small><strong>Nbr ventes du mois : <span class="bold" >{{kpi.nbSalesCurrentMonth}}</span></strong></small>
              </span>
            </div>
            <div class="form-group col-md-4">
              <span class="text-danger">
                <small><strong>Nbr ventes du mois precédent : <span class="bold" >{{kpi.nbSalesPrecedentMonth}}</span></strong></small>
              </span>
            </div>
          </div>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content >
        <table class="table">
          <thead class="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Qte</th>
            <th scope="col">Pourcentage (%)</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th scope="row" style="cursor: pointer" (click)="goOnClientParDetails(1)">PAR 1</th>
            <td>{{kpi.par1Units}}</td>
            <td>{{kpi.par1UnitsRate | percent}}</td>
          </tr>
          <tr>
            <th scope="row" style="cursor: pointer" (click)="goOnClientParDetails(3)">PAR 3</th>
            <td>{{kpi.par3Units}}</td>
            <td>{{kpi.par3UnitsRate | percent}}</td>
          </tr>
          <tr>
            <th scope="row" style="cursor: pointer" (click)="goOnClientParDetails(5)">PAR 5</th>
            <td>{{kpi.par5Units}}</td>
            <td>{{kpi.par5UnitsRate | percent}}</td>
          </tr>
          <tr>
            <th scope="row" style="cursor: pointer" (click)="goOnClientParDetails(10)">PAR 10</th>
            <td>{{kpi.par10Units}}</td>
            <td>{{kpi.par10UnitsRate | percent}}</td>
          </tr>
          <tr>
            <th scope="row" style="cursor: pointer" (click)="goOnClientParDetails(30)">PAR 30</th>
            <td>{{kpi.par30Units}}</td>
            <td>{{kpi.par30UnitsRate | percent}}</td>
          </tr>
          <tr>
            <th scope="row" style="cursor: pointer" (click)="goOnClientParDetails(60)">PAR 60</th>
            <td>{{kpi.par60Units}}</td>
            <td>{{kpi.par60UnitsRate | percent}}</td>
          </tr>
          <tr>
            <th scope="row" style="cursor: pointer" (click)="goOnClientParDetails(60)">PAR 90</th>
            <td>{{kpi.par90Units}}</td>
            <td>{{kpi.par90UnitsRate | percent}}</td>
          </tr>
          <tr>
            <th scope="row" style="cursor: pointer" (click)="goOnClientParDetails(120)">PAR 120</th>
            <td>{{kpi.par120Units}}</td>
            <td>{{kpi.par120UnitsRate | percent}}</td>
          </tr>
          </tbody>
        </table>
      </mat-card-content>
    </mat-card>
  </ng-container>
  <div *ngIf="globalKpis.length === 0">
    Aucune information trouvées
  </div>

</div>
