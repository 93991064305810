import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {

  @Input()
  public size = 'medium';
  @Input()
  public color = '#fff';
  @Input()
  public type = 'ball-clip-rotate-multiple';
  @Input()
  public  text = 'loading...';

  constructor() { }

  ngOnInit(): void { }

}
