import {Component, Input, OnInit} from '@angular/core';
import {QuestionBase} from '../../../data/QuestionBase';
import {FormBuilder, FormGroup} from '@angular/forms';
import {QuestionControlService} from '../../../services/question-control.service';
import {LocalCheckService} from '../../../services/local-check.service';
import {Router} from '@angular/router';
import {ItemData} from '../../../data/ItemData';
import {AnswerData} from '../../../data/AnswerData';
import {v4 as uuidv4} from 'uuid';
import {FormulaCalculatorService} from '../../../services/formula-calculator.service';
import {LocalStorageService} from '../../../services/local-storage.service';
import {AnswerResult} from '../../../data/AnswerResult';
import {RemoteCheckService} from '../../../services/remote-check.service';
import {Customer} from '../../../data/Customer';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css']
})
export class DynamicFormComponent implements OnInit {
  @Input() questions: QuestionBase<string>[] = [];
  form: FormGroup;
  payLoad = '';
  allAnswers: any = null;
  allResults: any = null;
  userEmail: string;

  constructor(private qcs: QuestionControlService,
              private router: Router,
              private builder: FormBuilder,
              private calculateService: FormulaCalculatorService,
              private localStorageService: LocalStorageService,
              private localCheckService: LocalCheckService,
              private remoteService: RemoteCheckService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    // Initialisation du formulaire avec les question opetnu en input
    this.form = this.qcs.toFormGroup(this.questions);
    this.allAnswers = this.localStorageService.loadInfo(ItemData.ANSWER.toString());
    this.allResults = this.localStorageService.loadInfo(ItemData.RESULT.toString());
    this.userEmail = this.userEmail ? this.userEmail : localStorage.getItem(ItemData.USER.toString());
  }

  onSubmit(): void {
    this.spinner.show();
    const formValue = this.form.getRawValue(); // récupération des information du formulaire

    const currentAge = this.getAgeFormDate(formValue.age);

    // Construction de l'age
    const currentCustomer: Customer = {
      age: formValue.age,
      names: formValue.names ? formValue.names : null,
      numeroInstall: formValue.numeroInstall ? formValue.numeroInstall : null,
      phone: formValue.phone ? formValue.phone : null,
      updatedAt: new Date(),
      createdAt: new Date()
    };

    // Construction de l'object réponse
    const answer: AnswerData = {
      code: uuidv4(),
      user_email: this.userEmail,
      revenues: Number(formValue.revenues),
      adamaoua: Number(formValue.adamaoua),
      age: currentAge,
      centre2: Number(formValue.centre2),
      bamileke: Number(formValue.bamileke),
      anglais: Number(formValue.anglais),
      autres: Number(formValue.autres),
      haricots: Number(formValue.haricots),
      cacao: Number(formValue.cacao),
      retraite: Number(formValue.retraite),
      independant: Number(formValue.independant),
      fonctionnaire: Number(formValue.fonctionnaire),
      eleveur: Number(formValue.eleveur),
      commercant: Number(formValue.commercant),
      artisan: Number(formValue.artisan),
      orange: Number(formValue.orange),
      ewondo: Number(formValue.ewondo),
      extra_downpayment: (formValue.extraDownpayment - formValue.product),
      manioc: Number(formValue.manioc),
      poisson: Number(formValue.poisson),
      veuf: Number(formValue.veuf),
      product: formValue.product,
      deleted: false,
      synchronised: false,
      customer_id: currentCustomer,
      createdAt: new Date(),
      updatedAt: new Date()
    };

    // Calcul du résultat à partir de la réponse
    const currentResult = this.calculateService.geResultModel60And90(answer);

    setTimeout(() => {
      this.saveAnsverAndResultOnBackend(answer, currentResult);
    }, 1000);
  }

  cancel(): void {
    this.router.navigate(['answer', 'list']);
  }

  private getAgeFormDate(date: Date): number {
    const today = new Date();
    return (today.getFullYear() - date.getFullYear());
  }

  updateAcomptePrix(event: any): void {
    this.questions.map(
      q => q.ordre === 8 ? q.title = 'Sachant que l\'acompte minimum est de ' + event.acompte + ', vous souhaitez payer un acompte de combien?' : q
    );
  }

  /**
   * Synchronize answer and result with the backend
   * @param answer
   * @param currentResult
   * @private
   */
  private saveAnsverAndResultOnBackend(answer: AnswerData, currentResult: AnswerResult): void {
    console.log('Synchronize Data');
    this.remoteService.genericMethodForPostRequest('/create-response', answer).then((value: any) => {
      if (value.status === 201) {
        answer.id = value.body.id;
        answer.customer_id = value.body.customer_id;
        answer.synchronised = value.body.synchronised;
        console.log(value);
        this.localStorageService.addDocumentOnCollection(ItemData.ANSWER.toString(), answer);
        this.saveResultToBackendAntToIndexedDb(currentResult);
      }
    }).catch(reason => {
      console.log(reason);
      this.localStorageService.addDocumentOnCollection(ItemData.ANSWER.toString(), answer);
      this.saveResultInIndexedDb(currentResult, 'Redirection while fail');
    });
    // On redirige vers la page des détails de la réponse en envoyant le code du résultat dans l'url
  }

  private saveResultToBackendAntToIndexedDb(currentResult: AnswerResult): void {
    console.log(currentResult);
    console.log('------->>> saveResultToBackendAntToIndexedDb code : ' + currentResult.code);
    this.remoteService.genericMethodForPostRequest('/create-results', currentResult).then((value: any) => {
      if (value.status === 201) {
        currentResult.id = value.body.id;
        currentResult.synchronised = value.body.synchronised;
        console.log(value);
        this.saveResultInIndexedDb(currentResult, 'Redirection while success');
      }
    }).catch(reason => {
      console.log(reason);
      this.saveResultInIndexedDb(currentResult, 'Redirection while fail');
    });
  }

  /**
   *
   * @param currentResult
   * @param message
   * @private
   */
  private saveResultInIndexedDb(currentResult: AnswerResult, message: string): void {
    this.localStorageService.addDocumentOnCollection(ItemData.RESULT.toString(), currentResult).then(value1 => {
      console.log(value1);
      this.spinner.hide();
      console.log(message);
      this.router.navigate(['result', 'details', value1.data.data.code]);
    });
  }
}
