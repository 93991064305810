import {Component, OnInit} from '@angular/core';
import {AgentKpiData} from '../../../data/AgentKpiData';
import {NgxSpinnerService} from 'ngx-spinner';
import {RemoteCheckService} from '../../../services/remote-check.service';
import {LocalStorageService} from '../../../services/local-storage.service';
import {DatePipe} from '@angular/common';
import {ItemData} from '../../../data/ItemData';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {Router} from '@angular/router';

@Component({
  selector: 'app-kpi-stats',
  templateUrl: './kpi-stats.component.html',
  styleUrls: ['./kpi-stats.component.css'],
  providers: [DatePipe]
})
export class KpiStatsComponent implements OnInit {

  // email = 'ayouba.ntchingou.upowa@gmail.com';
  email = localStorage.getItem(ItemData.USER);
  date = new Date();
  globalKpis: Array<AgentKpiData> = new Array<AgentKpiData>();

  constructor(private spinner: NgxSpinnerService, private remoteService: RemoteCheckService,
              private localService: LocalStorageService, private datePipe: DatePipe,
              private router: Router) { }

  ngOnInit(): void {
    this.getLocalAgentKpiInfos();
  }

  madeSync(): void {
    this.spinner.show();
    const dateFormat = this.datePipe.transform(this.date, ItemData.DATE_Y_M_D);
    this.remoteService.genericMethodForGettingDataForKPI('/agents/global-kpis?email=' + this.email + '&date=' + dateFormat)
      .then((value: Array<AgentKpiData>) => {
        if (isNotNullOrUndefined(value)) {
          this.globalKpis = value.length > 0 ? value : this.globalKpis;
          this.spinner.hide();
          this.localService.deleteCollection(ItemData.GLOBAL_KPI).then(value1 => {
            console.log(value1);
            value.forEach(item => {
              this.localService.addDocumentOnCollection(ItemData.GLOBAL_KPI, item);
            });
          });
        }
        console.log(value);
      }).catch(reason => {
      alert('Vérifiez votre connection et réessayer svp !');
      this.spinner.hide();
      console.log(reason);
    });
  }

  getLocalAgentKpiInfos(): void {
    this.spinner.show();
    this.localService.getAllData(ItemData.GLOBAL_KPI).then(value => {
      this.globalKpis = value;
      this.spinner.hide();
    }).catch(reason => {
      console.log(reason);
      this.spinner.hide();
    });
  }

  goOnClientParDetails(par: number): void {
    this.router.navigate(['agent', 'vente', par]);
  }
}
