export class QuestionsConstant {
  static fakeQuestions = [
    {
      createdAt: null,
      updatedAt: null,
      id: '1',
      title: 'Faites vous de l\'élevage de boeuf ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 5,
      controlType: 'radio',
      variable: '1',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '2',
      title: 'Faites vous de l\'élevage de chèvre ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 30,
      controlType: 'radio',
      variable: '2',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '3',
      title: 'Faites vous de l\'élevage de poules ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 34,
      controlType: 'radio',
      variable: '3',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '4',
      title: 'Faites vous de l\'élevage de mouton ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 41,
      controlType: 'radio',
      variable: '4',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '5',
      title: 'Faites vous de l\'élevage de porc ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 54,
      controlType: 'radio',
      variable: '5',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '6',
      title: 'Avez-vous déjà été contacté par un commercial d\'upowa précédement ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 2,
      controlType: 'radio',
      variable: '6',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '7',
      title: 'Connaissez-vous déjà un client d\'upowa ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 12,
      controlType: 'radio',
      variable: '6',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '8',
      title: 'Avez-vous déjà été contacté par un ambassadeur d\'upowa précédement ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 16,
      controlType: 'radio',
      variable: '8',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '9',
      title: 'Il y a-t\'il un boutique d\'upowa proche de vous ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 6,
      controlType: 'radio',
      variable: '9',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '10',
      title: 'Connaissez-vous les caravanes d\'upowa?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 19,
      controlType: 'radio',
      variable: '10',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '11',
      title: 'Cultivez-vous des arachides ?',
      dataType: 'select',
      valeur: null,
      required: false,
      ordre: 20,
      controlType: 'radio',
      variable: '11',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '12',
      title: 'Cultivez-vous des bananes ?',
      dataType: 'select',
      valeur: null,
      required: false,
      ordre: 35,
      controlType: 'radio',
      variable: '12',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '13',
      title: 'Cultivez-vous du ble ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 39,
      controlType: 'radio',
      variable: '13',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '14',
      title: 'Cultivez-vous du cafe ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 41,
      controlType: 'radio',
      variable: '14',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '15',
      title: 'Cultivez-vous du palmier ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 17,
      controlType: 'radio',
      variable: '15',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '16',
      title: 'Cultivez-vous du mais ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 23,
      controlType: 'radio',
      variable: '16',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '17',
      title: 'Le client est il un homme ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 28,
      controlType: 'radio',
      variable: '17',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '18',
      title: 'La cliente est elle une femme ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 26,
      controlType: 'radio',
      variable: '18',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '19',
      title: 'Est-ce que le domicile du client est alimenté par ENEO ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 26,
      controlType: 'radio',
      variable: '19',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '20',
      title: 'Le client a combien d\'enfants à charge ?',
      dataType: 'number',
      valeur: null,
      required: false,
      ordre: 36,
      controlType: 'textbox',
      variable: '20',
      options: []
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '21',
      title: 'Le client a combien d\'enfants à charge en âge scolaire ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 45,
      controlType: 'radio',
      variable: '21',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '22',
      title: 'Parlez vous Bamoun ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 15,
      controlType: 'radio',
      variable: '22',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '23',
      title: 'Parlez vous Bassa ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 28,
      controlType: 'radio',
      variable: '23',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '24',
      title: 'Parlez vous Foufouldé ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 30,
      controlType: 'radio',
      variable: '24',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '25',
      title: 'Parlez vous Français ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 40,
      controlType: 'radio',
      variable: '25',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '26',
      title: 'Vous parlez combien de langues ?',
      dataType: 'number',
      valeur: null,
      required: false,
      ordre: 53,
      controlType: 'textbox',
      variable: '26',
      options: []
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '27',
      title: 'Êtes-vous marié-e ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 57,
      controlType: 'radio',
      variable: '27',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '28',
      title: 'Êtes-vous polygamme ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 60,
      controlType: 'radio',
      variable: '28',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '29',
      title: 'Le réseau téléphonique MTN est disponible autour du domicile du (de la) client(e) ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 34,
      controlType: 'radio',
      variable: '29',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '30',
      title: 'Le réseau téléphonique Nextel est disponible autour du domicile du (de la) client(e) ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 26,
      controlType: 'radio',
      variable: '30',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '31',
      title: 'La région du client en l\'Ouest ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 38,
      controlType: 'radio',
      variable: '31',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '32',
      title: 'La région du client en le Centre 2 ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 54,
      controlType: 'radio',
      variable: '32',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '33',
      title: 'La région du client en le Littoral ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 21,
      controlType: 'radio',
      variable: '33',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '34',
      title: 'La région du client en l\'Est ?',
      dataType: 'radio',
      valeur: null,
      required: false,
      ordre: 25,
      controlType: 'radio',
      variable: '34',
      options: [
        {
          cle: '1',
          valeur: 'OUI'
        },
        {
          cle: '0',
          valeur: 'NON'
        }
      ]
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '35',
      title: 'Combien le client dépense d\'argent en recharge de téléphone par semaine ?',
      dataType: 'number',
      valeur: null,
      required: false,
      ordre: 61,
      controlType: 'textbox',
      variable: '35',
      options: []
    },
    {
      createdAt: null,
      updatedAt: null,
      id: '36',
      title: 'Combien le client dépense d\'argent pour se déplacer sur le lieu de recharge par semaine ?',
      dataType: 'number',
      valeur: null,
      required: false,
      ordre: 26,
      controlType: 'textbox',
      variable: '36',
      options: []
    }
    ];
}
