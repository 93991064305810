import {Component, OnInit} from '@angular/core';
import {ConnectionService} from 'ng-connection-service';
import {AuthService} from './services/auth.service';
import {LocalStorageService} from './services/local-storage.service';
import {Router} from '@angular/router';
import {RemoteCheckService} from './services/remote-check.service';
import {fromEvent, Observable, Subscription} from 'rxjs';
import {AnswerData} from './data/AnswerData';
import {AnswerResult} from './data/AnswerResult';
import {ItemData} from './data/ItemData';
import {LocalCheckService} from './services/local-check.service';
import {QuestionBase} from './data/QuestionBase';
import {NgxSpinnerService} from 'ngx-spinner';
import {InitializerData} from './data/InitializerData';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Modeles} from './data/Modeles';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {environment} from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  online: boolean;
  status = 'ONLINE';
  isConnected = isNotNullOrUndefined(localStorage.getItem(ItemData.USER));
  appIsUpdated: boolean;
  appVersion: string;
  deviceInfo = null;
  loardText = 'Chargement...';
  dataToSynchronised: boolean;
  synchroInterval: any;
  version = environment.APP_VERSION;

  // Observables & Subscriptions to check online/offline connectivity status
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  constructor(private connectionService: ConnectionService, private deviceService: DeviceDetectorService,
              private authService: AuthService,
              private localStorageService: LocalStorageService, private localService: LocalCheckService,
              private router: Router, private remoteService: RemoteCheckService,
              private spinner: NgxSpinnerService) {
    this.appVersion = localStorage.getItem(ItemData.UPDATE.toString());
    this.appIsUpdated = ItemData.VERSION.toString() === this.appVersion;
    this.authService.isAuthenticated().then(
      (value: any) => {
        this.online = !!value;
      });
    this.checkOnlineStatus();
  }

  ngOnInit(): void {
    this.isDataForSynchro();
    this.synchroInterval = setInterval(() => {
      this.isDataForSynchro();
    }, 3600000); // Timeout de 1 heure (3600000ms) pour la Synchro 60000ms (1 Minute)
  }

  async gotToHome(): Promise<void> {
    // await this.authService.signOut();
    // this.online = false;
    // this.localStorageService.clearAllLocalStorage();
    this.router.navigate(['home', 'page']);
  }

  goToResultList(): void {
    this.router.navigate(['home']);
  }

  // Get the online/offline connectivity status from browser window
  checkOnlineStatus = () => {
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(() => {
      this.online = true;
      console.log('Connected!');
      console.log('Checking data for synchronize!');
      this.madeSync();
    }));
    this.subscriptions.push(this.offlineEvent.subscribe(() => {
      this.online = false;
      console.log('Check Connectivity!');
    }));
  }

  public madeSync(): void {
    console.log('Connected!');
    console.log('Checking data for synchronize!');
    // console.log(QuestionsConstant.fakeQuestions);
    // check if there are non synchronized data on the database
    this.localStorageService.getOnDocumentWithSynchro(ItemData.RESULT.toString(), false).then(infos => {
      if (infos === undefined) {
        console.log('No synchro data found !!!');
        this.spinner.hide();
      } else {
        this.synchroProcess();
      }
    }).catch(reason => {
      console.log('No synchro data found !!!');
      this.spinner.hide();
    });
  }

  /**
   * Process de synchro
   * @private
   */
  private synchroProcess(): void {
    // 1. Récupération des information dans le localStorage
    this.localStorageService.getAllData(ItemData.RESULT.toString()).then((results: Array<AnswerResult>) => {
      results.filter(value => !value.synchronised).forEach(one => {
        this.synchronizeResultAfterDeleting(one);
      });
    });
    this.localStorageService.getAllData(ItemData.ANSWER.toString()).then((answers: Array<AnswerData>) => {
      answers.filter(value => !value.synchronised).forEach(one => {
        this.synchronizeAnswerAfterDeleting(one);
      });
    });
    setTimeout(() => {
      this.spinner.hide();
      this.isDataForSynchro();
    }, 20000);
  }

  /**
   * Synchronisation des données réponse sur le serveur
   * @param answer
   * @private
   */
  private synchronizeAnswerAfterDeleting(answer: AnswerData): void {
    this.remoteService.genericMethodForPostRequest('/create-response', answer).then((res: any) => {
      const value = res.body;
      if (res.status === 201) {
        console.log('Synchronisation of Answer OK');
        this.localStorageService.updateDocumentOnCollection(ItemData.ANSWER.toString(), answer.code, {
          id: value.id,
          customer_id: value.customer_id,
          synchronised: value.synchronised
        });
      } else if (res.status === 409) {
        this.localStorageService.updateDocumentOnCollection(ItemData.ANSWER.toString(), answer.code, {synchronised: value.synchronised});
      }
      console.log(value.code);
    }).catch(reason => {
      console.log(reason);
    });
  }

  /**
   * Synchronisation des données de réusltat
   * @param currentResult
   * @private
   */
  private synchronizeResultAfterDeleting(currentResult: AnswerResult): void {
    this.remoteService.genericMethodForPostRequest('/create-results', currentResult).then((res: any) => {
      const value = res.body;
      if (res.status === 201) {
        console.log('Synchronization of Result OK');
        this.localStorageService.updateDocumentOnCollection(ItemData.ANSWER.toString(), currentResult.code, {
          id: value.id,
          synchronised: value.synchronised
        });
      } else if (res.status === 409) {
        this.localStorageService
          .updateDocumentOnCollection(ItemData.ANSWER.toString(), currentResult.code, {synchronised: value.synchronised});
      }
      console.log(value.code);
    }).catch(reason => {
      console.log(reason);
    });
  }

  /**
   * Update app for V1.1
   */
  public updateApp(): void {
    this.spinner.show();
    if (ItemData.VERSION.toString() !== this.appVersion) {
      // Pour savoir les utilisateur qui on fait le mise à jour de version de l'application
      this.epicFunction({
        userName: localStorage.getItem(ItemData.USER.toString()) + ' get ' + ItemData.VERSION.toString(),
        userEmail: localStorage.getItem(ItemData.USER.toString()) + ' get ' + ItemData.VERSION.toString()
      });
      // Mise à jour des question de l'application
      // this.gettingQuestionFromTheServer();
      // Mise à jour de la base de données de l'application
      this.transferDataFromLacalStorageToIndexedDB();
    }
  }

  private gettingQuestionFromTheServer(): void {
    // Récupération des questions
    this.remoteService.genericMethodForGettingData('/questions')
      .then((quests: any) => {
        const questions = quests as Array<QuestionBase<any>>;
        this.transformAndSaveQuestion(questions);
        this.appIsUpdated = true;
        localStorage.setItem(ItemData.UPDATE.toString(), ItemData.VERSION.toString());
        this.spinner.hide();
        this.router.navigate(['updated']);
      })
      .catch(reason => {
        console.log(reason);
        this.spinner.hide();
      });
  }

  private transformAndSaveQuestion(questions: Array<QuestionBase<any>>): void {
    questions.map(
      q => q.controlType === 'radio' ?
        q.options = [{cle: '1', valeur: 'OUI'}, {cle: '0', valeur: 'NON'}] :
        q.controlType === 'dropdown' ?
          q.options = [{cle: '10000', valeur: 'Flash'}, {cle: '15000', valeur: 'Luciole'}] :
          q
    );
    this.localStorageService.setInfo(ItemData.QUESTION.toString(), questions);
  }

  epicFunction(initData: InitializerData): void {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.deviceInfo.isMobile = this.deviceService.isMobile();
    this.deviceInfo.isTablet = this.deviceService.isTablet();
    this.deviceInfo.isDesktopDevice = this.deviceService.isDesktop();
    initData.phoneModel = JSON.stringify(this.deviceInfo);
    this.remoteService.genericMethodForPostRequestV1('/create-initializer', initData);
  }

  private transferDataFromLacalStorageToIndexedDB(): void {
    // Get Modèle in localstorage and send it in IndexedDB
    this.transfertModele(ItemData.MODELS.toString());

    // Get Question in localStorage and send it in IndexedDB
    this.transfertQuestion(ItemData.QUESTION.toString());

    // Get Result and Answers in localStorage and send it in IndexedDB
    this.transferResultAndQuestion(ItemData.RESULT.toString(), ItemData.ANSWER.toString());

    this.cleanLocalStorage();
  }

  /**
   * Transferring Model's data from localStorage to idnexedDB
   *
   * @param collection
   * @private
   */
  private transfertModele(collection: string): void {
    const models60 = this.localStorageService.loadInfo(ItemData.MD60.toString()) as Modeles;
    const models90 = this.localStorageService.loadInfo(ItemData.MD90.toString()) as Modeles;
    if (!(models60 === null || models60 === undefined)) {
      this.localStorageService.addDocumentOnCollectionWithKey(collection, models60, ItemData.MD60.toString());
      this.localStorageService.addDocumentOnCollectionWithKey(collection, models90, ItemData.MD90.toString());
    }
  }

  /**
   * Transferring Questions data from localStorage to idnexedDB
   * @param collection
   * @private
   */
  private transfertQuestion(collection: string): void {
    const questions = this.localStorageService.loadInfo(ItemData.QUESTION.toString()) as Array<QuestionBase<any>>;
    if (!(questions === null || questions === undefined)) {
      questions.forEach(question => this.localStorageService.addDocumentOnCollection(collection, question));
    }
    // this.localStorageService.setCollection(collection, questions);
  }

  /**
   * Transferring Answer and Result data from localStorage to idnexedDB
   * @param collectionResult
   * @param collectionAnswer
   * @private
   */
  private transferResultAndQuestion(collectionResult: string, collectionAnswer: string): void {
    const results = this.localStorageService.loadInfo(ItemData.RESULT.toString()) as Array<AnswerResult>;
    const answers = this.localStorageService.loadInfo(ItemData.ANSWER.toString()) as Array<AnswerResult>;

    if (!(results === null || results === undefined)) {
      results.forEach(result => this.localStorageService.addDocumentOnCollection(collectionResult, result));
      answers.forEach(answer => this.localStorageService.addDocumentOnCollection(collectionAnswer, answer));
    }
  }

  private cleanLocalStorage(): void {
    localStorage.removeItem(ItemData.RESULT.toString());
    localStorage.removeItem(ItemData.QUESTION.toString());
    localStorage.removeItem(ItemData.ANSWER.toString());
    localStorage.removeItem(ItemData.MD90.toString());
    localStorage.removeItem(ItemData.MD60.toString());
    localStorage.removeItem(ItemData.USER);
    localStorage.setItem(ItemData.UPDATE.toString(), ItemData.VERSION.toString());
    this.spinner.hide();
    this.router.navigate(['home', 'page']).then(value => {
      console.log(value);
      location.reload();
    });
  }

  /**
   * Check if there is data for synchro
   * @private
   */
  private isDataForSynchro(): void {
    console.log('Checking data for synchro....');
    // check if there are non synchronized data on the database
    if (!this.dataToSynchronised) {
      this.localStorageService.getAllData(ItemData.RESULT.toString()).then((infos: any) => {
        const result = infos.filter(res => !res.synchronised)[0];
        this.dataToSynchronised = !!isNotNullOrUndefined(result);
      }).catch(reason => {
        this.dataToSynchronised = false;
        console.log('No synchro data found  in Result IDDB  !!!');
        console.log(reason);
      });
    }
  }
}
