import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-kpi-produit',
  templateUrl: './kpi-produit.component.html',
  styleUrls: ['./kpi-produit.component.css']
})
export class KpiProduitComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
