import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {HomeComponent} from './components/home/home.component';
import {AnswerListComponent} from './components/answer-list/answer-list.component';
import {AnswerComponent} from './components/answer/answer.component';
import {AnswerDetailComponent} from './components/answer-detail/answer-detail.component';
import {ResultDetailComponent} from './components/result-detail/result-detail.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AuthService} from './services/auth.service';
import {AuthGuardService} from './services/auth-guard.service';
import {RemoteCheckService} from './services/remote-check.service';
import {LocalCheckService} from './services/local-check.service';
import {LocalStorageService} from './services/local-storage.service';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {MatListModule} from '@angular/material/list';
import {DynamicFormQuestionComponent} from './components/answer/dynamic-form-question/dynamic-form-question.component';
import {QuestionControlService} from './services/question-control.service';
import {DynamicFormComponent} from './components/answer/dynamic-form/dynamic-form.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {DialogDeleteComponent} from './components/dialog-delete/dialog-delete.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {CachingInterceptor} from './services/cache/caching-interceptor.service';
import {NgxSpinnerModule} from 'ngx-spinner';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {HomePageComponent} from './components/home-page/home-page.component';
import {KpiStatsComponent} from './components/agents/kpi-stats/kpi-stats.component';
import {ClientParComponent} from './components/agents/client-par/client-par.component';
import {KpiVenteComponent} from './components/agents/kpi-vente/kpi-vente.component';
import {KpiProduitComponent} from './components/agents/kpi-produit/kpi-produit.component';
import {KpiAgentComponent} from './components/agents/kpi-agent/kpi-agent.component';
import {AnswerItemComponent} from './components/answer-list/answer-item/answer-item.component';
import {VenteItemComponent} from './components/agents/kpi-vente/vente-item/vente-item.component';
import {NgxPaginationModule} from "ngx-pagination";
import {DetailsComponent} from './components/agents/kpi-vente/details/details.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AnswerListComponent,
    AnswerComponent,
    AnswerDetailComponent,
    ResultDetailComponent,
    DynamicFormQuestionComponent,
    DynamicFormComponent,
    DialogDeleteComponent,
    SpinnerComponent,
    HomePageComponent,
    KpiStatsComponent,
    ClientParComponent,
    KpiVenteComponent,
    KpiProduitComponent,
    KpiAgentComponent,
    AnswerItemComponent,
    VenteItemComponent,
    DetailsComponent
  ],
    imports: [
        BrowserModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatMenuModule,
        MatIconModule,
        MatCardModule,
        MatButtonModule,
        MatTableModule,
        MatDividerModule,
        MatProgressSpinnerModule,
        MatListModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AngularFireModule.initializeApp(environment.FIREBASE_CONFIG),
        AppRoutingModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatRadioModule,
        MatButtonToggleModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatDialogModule,
        NgxSpinnerModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        NgxPaginationModule
    ],
  providers: [
    AuthService,
    AuthGuardService,
    RemoteCheckService,
    LocalCheckService,
    LocalStorageService,
    QuestionControlService,
    {provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
