import {Injectable} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {QuestionBase} from '../data/QuestionBase';

@Injectable()
export class QuestionControlService {

  constructor() { }

  toFormGroup(questions: QuestionBase<string>[] ): FormGroup {
    const group: any = {};

    questions.forEach(question => {
      group[question.variable] = question.required ? new FormControl(question.valeur || '', Validators.required)
        : new FormControl(question.valeur || '');
    });
    return new FormGroup(group);
  }
}
