import {Injectable} from '@angular/core';
import {LocalStorageService} from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LocalCheckService {

  constructor(private localStorageService: LocalStorageService) { }

  public getData(item: string): any {
    return this.localStorageService.loadInfo(item);
  }

  public updateData(item: string, data: any): any {
    this.localStorageService.clearInfo(item);
    return this.localStorageService.setInfo(item, data);
  }

}
