import {Injectable} from '@angular/core';
import firebase from 'firebase';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable, of} from 'rxjs';
import {Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import {first, switchMap} from 'rxjs/operators';
import UserCredential = firebase.auth.UserCredential;
import auth = firebase.auth;

interface User {
  uid: string;
  email: string;
  photoURL?: string;
  displayName?: string;
  favoriteColor?: string;
  phoneNumber?: string;
}

@Injectable()
export class AuthService {
  user: Observable<User>;

  constructor(private router: Router, private afAuth: AngularFireAuth, private afs: AngularFirestore) {
    this.user = this.afAuth.authState.pipe(switchMap(user => {
      if (user) {
        return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
      } else {
        return of(null);
      }
    }));
  }

  extractUserCredential(credential: UserCredential): UserCredential {
    let user;
    if (credential) {
      user = credential;
    }
    return user;
  }

  googleLogin(): Promise<any>{
    const provider = new auth.GoogleAuthProvider();
    return this.oAuthRSLogin(provider);
  }

  oAuthRSLogin(provider): Promise<any> {
    return this.afAuth.signInWithPopup(provider).then(this.extractUserCredential);
  }
  isAuthenticated(): Promise<any> {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

 /* isAuthenticated(): boolean {
    const user = localStorage.getItem(ItemData.USER.toString());
    return !!user;
  }*/

  signOut = () => {
    this.afAuth.signOut().then(() => {
      this.router.navigate(['/']);
    });
  }
}
