export enum ItemData{
  ANSWER = 'answers',
  QUESTION = 'questions',
  RESULT = 'results',
  MD60= 'MD60',
  MODELS= 'models',
  MD90 = 'MD90',
  USER = 'email',
  UPDATE = 'update',
  VERSION = 'V2.0.1',
  RANDOM = 'random',
  GLOBAL_KPI = 'global_kpi',
  AGENT_SALES = 'sales',
  DATE_Y_M_D = 'yyyy-MM-dd'
}
