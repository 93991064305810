import {Component, OnInit} from '@angular/core';
import {ItemData} from '../../data/ItemData';
import {FormArray} from '@angular/forms';
import {QuestionBase} from '../../data/QuestionBase';
import {AnswerData} from '../../data/AnswerData';
import {LocalStorageService} from '../../services/local-storage.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {QuestionsConstant} from '../../data/QuestionsConstant';

@Component({
  selector: 'app-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.css']
})
export class AnswerComponent implements OnInit {
  questionList: Array<QuestionBase<any>> = new Array<QuestionBase<any>>();
  answers: Array<AnswerData> = new Array<AnswerData>();
  items: FormArray;
  // questions$: Observable<QuestionBase<any>[]>;

  constructor(private localStorageService: LocalStorageService,
              private spinner: NgxSpinnerService) {

  }

  ngOnInit(): void {
    this.spinner.show();
    this.initForm();
  }

  private initForm(): void {
    this.localStorageService.getAllData(ItemData.QUESTION.toString()).then((value: Array<QuestionBase<any>>) => {
      // Getting random fake questions
      const fake5Question = QuestionsConstant.fakeQuestions.sort(() => 0.5 - Math.random()) as Array<QuestionBase<any>>;
      this.questionList = value;
      // add 5 fake question to the question list
      fake5Question.slice(0, 5).forEach(fq => {
        this.questionList.push(fq);
      });
      this.questionList.sort((a, b) => a.ordre - b.ordre);
      this.spinner.hide();
    }).catch(reason => {
      console.log(reason);
      this.spinner.hide();
    });
  }
}
