import {Component, Input, OnInit} from '@angular/core';
import {AgentClientData} from '../../../../data/AgentClientData';
import {Router} from '@angular/router';

@Component({
  selector: 'app-vente-item',
  templateUrl: './vente-item.component.html',
  styleUrls: ['./vente-item.component.css']
})
export class VenteItemComponent implements OnInit {
  @Input() globalSales: Array<AgentClientData> = new Array<AgentClientData>();
  p = 0;
  statusList: string[] = ['Activé', 'Remboursé', 'En attente acompte'];

  constructor(private router: Router) { }

  ngOnInit(): void {

  }

  onDetails(item: AgentClientData): void {
    this.router.navigate(['agent', 'vente', 'details', item.contractNumber]);
  }
}
