<app-spinner [text]="loardText"></app-spinner>
<mat-toolbar class="upowa-color-primary">
  <button mat-icon-button class="example-icon" aria-label="Example icon-button with highlight icon">
    <mat-icon (click)="goToResultList()">highlight</mat-icon>
  </button>
  <span>Master Check</span>
  <button *ngIf="dataToSynchronised" style="transform: scale(1.5); margin-left: 25px" matTooltip="Mettre à jour l'application" mat-icon-button class="example-icon brightness_1-icon" aria-label="Example icon-button with brightness_1 icon" (click)="madeSync()">
    <mat-icon>sync</mat-icon>
  </button>
  <span class="example-spacer"></span>
  <button *ngIf="!appIsUpdated" matTooltip="Mettre à jour l'application" mat-icon-button class="example-icon brightness_1-icon " (click)="updateApp()" aria-label="Example icon-button with brightness_1 icon">
    <mat-icon>update</mat-icon>
  </button>

  <!-- Menu button -->
  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" *ngIf="isConnected">
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
  <!--  Menu level button  -->
    <button mat-menu-item (click)="gotToHome()">
      <mat-icon>home</mat-icon>
      <span>Home</span>
    </button>

    <!--  Menu Credit Check  -->
    <button mat-menu-item [matMenuTriggerFor]="masterCheck">
      <mat-icon>check</mat-icon>
      <span>Credit Check</span>
    </button>
    <mat-menu #masterCheck="matMenu">
      <button mat-menu-item routerLink="answer/list">Mes C.C.</button>
      <button mat-menu-item routerLink="answer/new">Ajouter un C.C.</button>
    </mat-menu>

    <!--  Menu KPI Agent  -->
    <button mat-menu-item [matMenuTriggerFor]="kpiAgent">
      <mat-icon>dashboard</mat-icon>
      <span>Agents</span>
    </button>

    <mat-menu #kpiAgent="matMenu">
      <button mat-menu-item  routerLink="agent/kpi">Mon Dashboard</button>
      <button mat-menu-item routerLink="agent/vente">Mes clients</button>
    </mat-menu>

    <hr>
    <div class="text-center" style="font-size: x-small"><small><i>APP VERSION : {{version}}</i></small></div>
  </mat-menu>
</mat-toolbar>
<div class="container">
  <div></div>
  <div>
    <router-outlet></router-outlet>
  </div>
  <div></div>
</div>
