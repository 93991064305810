import {Component, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {RemoteCheckService} from '../../../services/remote-check.service';
import {LocalStorageService} from '../../../services/local-storage.service';
import {DatePipe} from '@angular/common';
import {AgentClientData} from '../../../data/AgentClientData';
import {ItemData} from '../../../data/ItemData';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatSelectChange} from '@angular/material/select';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-kpi-vente',
  templateUrl: './kpi-vente.component.html',
  styleUrls: ['./kpi-vente.component.css'],
  providers: [DatePipe]
})
export class KpiVenteComponent implements OnInit {
  email = localStorage.getItem(ItemData.USER);
  // email = 'ayouba.ntchingou.upowa@gmail.com';
  globalSales: Array<AgentClientData> = new Array<AgentClientData>();
  par: number;
  searchForm: FormGroup;
  name: any;
  installNumber: any;
  status: any;
  statusList: string[] = [null, 'Activé', 'Désactivé', 'Désinstallé', 'Blacklisté', 'Remboursé', 'En attente acompte'];
  parList: number[] = [null, 1, 3, 5, 10, 30, 60, 90, 120];
  constructor(private spinner: NgxSpinnerService, private remoteService: RemoteCheckService,
              private localService: LocalStorageService, private datePipe: DatePipe,
              private builder: FormBuilder, private route: ActivatedRoute,
              private router: Router) {
    this.par = this.route.snapshot.params.par;
  }

  ngOnInit(): void {
    if (isNotNullOrUndefined(this.par)) {
      const event: MatSelectChange = {
        source: null,
        value: this.par
      };
      this.onFilterByPAR(event);
    } else {
      this.getLocalCustomers();
    }
  }

  madeSync(): void {
    this.spinner.show();
    this.remoteService.genericMethodForGettingDataForKPI('/agents/sales?email=' + this.email)
      .then((value: Array<AgentClientData>) => {
        if (isNotNullOrUndefined(value)) {
          if (value.length > 0) {
            this.globalSales = value;
            this.localService.deleteCollection(ItemData.AGENT_SALES).then((value1: Array<AgentClientData>) => {
              console.log(value1);
              value.forEach(item => {
                this.localService.addDocumentOnCollection(ItemData.AGENT_SALES, item);
              });
              this.spinner.hide();
            });
          }
        }
        console.log(value);
      }).catch(reason => {
      this.spinner.hide();
      alert('Ventes non trouvées !!\n Ou bien vérifiez votre connection et réessayer svp !');
      console.log(reason);
    });
  }

  private getLocalCustomers(): void {
    this.spinner.show();
    this.localService.getAllData(ItemData.AGENT_SALES).then((value: Array<AgentClientData>) => {
      this.globalSales = value;
      this.spinner.hide();
    }).catch(reason => {
      console.log(reason);
      this.spinner.hide();
    });
  }

  onFilterByName(event: any): void {
    this.spinner.show();
    console.log(event);
    if (isNotNullOrUndefined(event)) {
      this.localService.getAllData(ItemData.AGENT_SALES).then((value: Array<AgentClientData>) => {
        this.globalSales = value.filter((item) => item.customerFirstName.includes(event));
        this.spinner.hide();
      }).catch(reason => {
        console.log(reason);
        this.spinner.hide();
      });
    } else {
      this.getLocalCustomers();
    }
  }

  onFilterByInstallNumber(event: any): void {
    this.spinner.show();
    console.log(event);
    if (isNotNullOrUndefined(event)) {
      this.localService.getAllData(ItemData.AGENT_SALES).then((value: Array<AgentClientData>) => {
        this.globalSales = value.filter((item) => item.contractNumber === event);
        this.spinner.hide();
      }).catch(reason => {
        console.log(reason);
        this.spinner.hide();
      });
    } else {
      this.getLocalCustomers();
    }
  }

  onFilterByPAR(event: MatSelectChange): void {
    this.spinner.show();
    console.log(event);
    if (isNotNullOrUndefined(event.value)) {
      this.localService.getAllData(ItemData.AGENT_SALES).then((value: Array<AgentClientData>) => {
        const disableCustomer = value.filter((item) => item.contractStatus === this.statusList[2]);
        this.globalSales = disableCustomer.filter((item) =>
          (((new Date().getTime() - new Date(Date.parse(item.contractSwitchOffDate)).getTime()) / (1000 * 3600 * 24)) >= event.value));
        this.spinner.hide();
      }).catch(reason => {
        console.log(reason);
        this.spinner.hide();
      });
    } else {
      this.getLocalCustomers();
    }
  }

  onFilterByStatus(event: MatSelectChange): void {
    this.spinner.show();
    console.log(event.value);
    if (isNotNullOrUndefined(event.value)) {
      this.localService.getAllData(ItemData.AGENT_SALES).then((value: Array<AgentClientData>) => {
        this.globalSales = value.filter((item) => item.contractStatus === event.value);
        this.spinner.hide();
      }).catch(reason => {
        console.log(reason);
        this.spinner.hide();
      });
    } else {
      this.getLocalCustomers();
    }
  }
}
