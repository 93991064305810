import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AnswerResult} from '../../data/AnswerResult';
import {LocalStorageService} from '../../services/local-storage.service';
import {ItemData} from '../../data/ItemData';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-result-detail',
  templateUrl: './result-detail.component.html',
  styleUrls: ['./result-detail.component.css']
})
export class ResultDetailComponent implements OnInit {

  ref: string;
  result: AnswerResult;
  allResults: any = null;

  constructor(private router: Router,
              private activeRoute: ActivatedRoute,
              private localStorageService: LocalStorageService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    if (!this.ref) {
      this.ref = this.activeRoute.snapshot.params.ref;
      this.localStorageService.getOnDocumentWithCode(ItemData.RESULT.toString(), this.ref).then(value => {
        this.result = value;
        this.spinner.hide();
      }).catch(reason => {
        console.log(reason);
        this.spinner.hide();
      });
    }
  }

  backToResultList(): void {
    this.router.navigate(['answer', 'list']);
  }

  addOneMore(): void {
    this.router.navigate(['answer', 'new']);
  }
}
